import React from "react"

const CardProcess = ({ title, content }) => {
  return (
    <section className="border border-black rounded text-mbr_blue px-12 py-12">
      <h5 className="mb-4">{title}</h5>
      <p className="small">{content}</p>
    </section>
  )
}

// px 56
// pt 72

export default CardProcess
