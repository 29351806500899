import React from "react"

const PaleWave = () => {
  return (
    <svg
      className="absolute w-screen h-auto left-0 bottom-0"
      width="1439"
      height="122"
      viewBox="0 0 1439 122"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 26.9371L34.1763 23.4951C68.5024 19.9035 136.855 13.0196 205.357 13.1692C273.86 13.4685 342.212 20.9511 410.715 28.7329C479.217 36.3651 547.57 44.4462 616.372 60.6084C685.024 76.7706 753.976 101.313 822.628 112.687C891.43 124.21 959.783 122.713 1028.29 118.673C1096.79 114.782 1165.14 108.197 1233.64 87.9944C1302.15 67.7916 1370.5 33.9706 1404.82 16.9105L1439 0V122H1404.82C1370.5 122 1302.15 122 1233.64 122C1165.14 122 1096.79 122 1028.29 122C959.783 122 891.43 122 822.628 122C753.976 122 685.024 122 616.372 122C547.57 122 479.217 122 410.715 122C342.212 122 273.86 122 205.357 122C136.855 122 68.5024 122 34.1763 122H0L0 26.9371Z"
        fill="#F3F5F0"
      />
    </svg>
  )
}

export default PaleWave
