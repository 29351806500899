import React, { useState, useRef } from "react"
import { FaChevronRight } from "react-icons/fa"

const Accordion = ({ title, content }) => {
  const [active, setActive] = useState(false)
  const [height, setHeight] = useState("0px")
  const [rotate, setRotate] = useState("transform duration-700 ease")

  const contentSpace = useRef(null)

  function toggleAccordion() {
    setActive(prevState => !prevState)
    setHeight(active ? "0px" : `${contentSpace.current.scrollHeight}px`)
    setRotate(
      active
        ? "transform duration-700 ease"
        : "transform duration-700 ease rotate-90"
    )
  }

  return (
    <div className="flex flex-col text-mbr_blue">
      <button
        className="py-6 box-border appearance-none cursor-pointer focus:outline-none flex items-center"
        onMouseEnter={toggleAccordion} onMouseLeave={toggleAccordion}
      >
        <h5 className="inline-block hover:font-extrabold text-footnote text-left light">
          {title}
        </h5>
        <FaChevronRight
          style={{ size: "2em" }}
          className={`hover:font-extrabold ml-4 ${rotate}`}
        />
      </button>
      <div
        ref={contentSpace}
        style={{ maxHeight: `${height}` }}
        className="overflow-hidden transition-max-height duration-700 ease-in-out"
      >
        <div className="pb-10">
          <p className="small">{content}</p>
        </div>
      </div>
    </div>
  )
}

export default Accordion
