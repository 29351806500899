import React from "react"

const Button = ({ color, content }) => {
  return (
    <button
      className={`bg-mbr_${color} rounded px-12 h-20 border-2 border-mbr_blue w-full sm:w-auto `}
    >
      <div
        className={
          color === "blue"
            ? "text-mbr_pale flex items-center w-full justify-between"
            : "text-mbr_blue flex items-center w-full justify-between"
        }
      >
        <h4 className="mr-2">{content}</h4>
        <svg
          width="25"
          height="24"
          viewBox="0 0 25 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M24.4382 11.1843L11.7034 0.13146C11.6056 0.0471908 11.4809 0 11.3494 0H8.36629C8.11685 0 8.00225 0.310112 8.19101 0.47191L19.9955 10.7191H0.269663C0.121348 10.7191 0 10.8404 0 10.9888V13.0112C0 13.1596 0.121348 13.2809 0.269663 13.2809H19.9921L8.18764 23.5281C7.99888 23.6933 8.11348 24 8.36292 24H11.4472C11.5112 24 11.5753 23.9764 11.6225 23.9326L24.4382 12.8157C24.5549 12.7143 24.6485 12.5889 24.7126 12.4482C24.7767 12.3075 24.8099 12.1546 24.8099 12C24.8099 11.8454 24.7767 11.6925 24.7126 11.5518C24.6485 11.4111 24.5549 11.2857 24.4382 11.1843Z"
            fill={color === "blue" ? "#F3F5F0" : "#004068"}
          />
        </svg>
      </div>
    </button>
  )
}

export default Button
