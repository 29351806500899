import React from 'react'

const CardProcessContainer = (props) => {
    return (
        <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-6">
            {props.children}
        </div>
    )
}

export default CardProcessContainer
