import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import Layout from "../layouts/Layout"
import CardProcess from "../components/CardProcess"
import CardProcessContainer from "../components/CardProcessContainer"
import Button from "../components/UI/Button"
import SiteMetadata from "../components/SiteMetadata"
import Accordion from "../components/Accordion"
import PaleWave from "../components/Shapes/PaleWave"
import GreenWave from "../components/Shapes/GreenWave"


const AboutPage = ({ data, location }) => {
  const { whatWeDo, process, clients } = data

  const logosArray = clients.nodes[0].logos.map(logo => getImage(logo))

  return (
    <Layout path={location.pathname}>
      <SiteMetadata title="About Made By Rise" description="What we do, process, and our clients" />
      <div className="px-0 lg:px-16 pb-64 pt-32 container">
        <h2 className="text-mbr_orange mb-12">What We Do</h2>
        {whatWeDo &&
          whatWeDo.nodes.map(item => (
            <Accordion title={item.title} content={item.content.content} />
          ))}
      </div>
      <div className="relative">
        <PaleWave />
      </div>
      <div className="px-12 lg:px-28 pb-32 pt-16 bg-mbr_pale">
        <h2 className="text-mbr_orange mb-12">Our Process</h2>
        <CardProcessContainer>
          {
            process && 
              process.nodes.map(item => (<CardProcess title={item.title} content={item.content.content} />))
          }
          <div className="flex flex-col justify-center items-center px-8 text-mbr_orange md:m-auto mt-12">
            <div className="mb-4">
              <svg
                width="135"
                height="24"
                viewBox="0 0 135 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <ellipse
                  cx="12.0251"
                  cy="12"
                  rx="11.8957"
                  ry="12"
                  fill="#E24C38"
                />
                <ellipse
                  cx="67.5385"
                  cy="12"
                  rx="11.8957"
                  ry="12"
                  fill="#E24C38"
                />
                <ellipse
                  cx="123.051"
                  cy="12"
                  rx="11.8957"
                  ry="12"
                  fill="#E24C38"
                />
              </svg>
            </div>
            <p className="small">
              We do these and much more. Talk to us if you don't see what you're
              looking for.
            </p>
          </div>
        </CardProcessContainer>
        </div>
        <div className="relative">
          <GreenWave />
        </div>
        <div className="container px-12 md:px-16 pt-32">
          <h2 className="text-mbr_orange mb-12">Who we have helped</h2>
          { clients.nodes[0].tagline && <p className="small text-mbr_blue">{clients.nodes[0].tagline}</p>}
          <div className="flex flex-wrap py-12 justify-center md:justify-start items-center">
            { logosArray && 
              logosArray.map(item =>
                <div className="object-fit max-w-full my-8 lg:my-10 mx-8 lg:mx-20"> 
                <GatsbyImage 
                className="m-1" 
                image={item} 
                transformOptions={{ grayscale: true }}
                />
              </div>)}
          </div>
        </div>
        <div className="container flex flex-col justify-around items-start h-56 my-20 ">
          <Link to="/contact" className="w-full sm:w-auto">
            <Button content="Contact Us" color="blue" />
          </Link>
          <Link to="/projects" className="w-full sm:w-auto">
            <Button content="Our Projects" color="pale" />
          </Link>
        </div>
    </Layout>
  )
}

export default AboutPage

export const query = graphql`
  query {
    whatWeDo: allContentfulAboutWhatWeDo(sort: {order: ASC, fields: sequence}) {
      nodes {
        title
        content {
          content
        }
      }
    }
    process:   allContentfulAboutProcess(sort: {order: ASC, fields: sequence}) {
      nodes {
        title
        content {
          content
        }
      }
    }
    clients: allContentfulClients {
      nodes {
        tagline
        logos {
          gatsbyImageData(
            width: 200
            placeholder: BLURRED
            formats: [AUTO, WEBP]
          )
        }
      }
    }
  }
`
